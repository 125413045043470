@use "sass:math";

span.multiselect-native-select {
  position: relative
}

span.multiselect-native-select select {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px -1px -1px -3px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  left: 50%;
  top: 30px;
}

.multiselect-container {
  position: absolute;
  list-style-type: none;

  .input-group {
    margin: 5px;
  }

  .multiselect-reset {
    .input-group {
      width: 93%;
    }
  }

  > li {
    padding: 0;

    > a.multiselect-all label {
      font-weight: bold;
    }

    label {
      margin: 0;
      padding: $nav-link-padding-y $nav-link-padding-x;
      height: 100%;
      display: block;
    }

    &.multiselect-group-clickable label {
      cursor: pointer;
    }

    &.active > a:hover,
    &.active > a {
      background: $dropdown-link-hover-bg;
      color: $dropdown-link-hover-color;
      label {
        font-weight: bold;
      }
    }

    > a {
      padding: 0;
      display: block;
      cursor: pointer;
      text-decoration: none;
      color: $dropdown-link-color;

      &:hover {
        background: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
      }

      > label {
        margin: 0;
        height: 100%;
        cursor: pointer;
        font-weight: normal;
        padding: $nav-link-padding-y $nav-link-padding-x;

        &.radio, &.checkbox {
          margin: 0;
        }

        > input {
          margin: -4px 5px 0 0;
        }
      }
    }

    &.multiselect-group {
      label {
        text-transform: uppercase;
        font-size: math.div($font-size-base, 1.2);
        color: $primary;
        font-weight: bold;
      }
    }
  }
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.form-inline .multiselect-container {

  label.checkbox, label.radio {
    padding: 3px 20px 3px 40px;
  }

  li a label {

    &.checkbox input[type="checkbox"], &.radio input[type="radio"] {
      margin-left: -20px;
      margin-right: 0;
    }
  }
}