$primary-color: $primary !default;
$ball-size: 15px !default;
$margin: 2px !default;
$line-height: 35px !default;
$line-width: 4px !default;

@mixin global-bg() {
  background-color: $primary-color;
}

@mixin global-animation() {
  animation-fill-mode: both;
}

@mixin balls() {
  @include global-bg();

  width: $ball-size;
  height: $ball-size;
  border-radius: 100%;
  margin: $margin;
}

@mixin lines() {
  @include global-bg();

  width: $line-width;
  height: $line-height;
  border-radius: 2px;
  margin: $margin;
}

@import '~scss-loading-animations/src/loaders';


// Block loading

.blockOverlay {
  @extend .modal-backdrop;
  display: block !important;
  opacity: .6;
  z-index: 55;
}

.blockElement {
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 66;
  height: 100%;
  width: 100%;
}

.blockPage {
  left: 50%;
  top: 50%;
  z-index: 66;

  .d-none {
    display: block !important;
  }

  .loader {
      @include loader01;
  }
}
