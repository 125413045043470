.breadcrumb {
    display: inline;
    flex-wrap: wrap;
    margin-bottom: $breadcrumb-margin-bottom;
    padding: 0 !important;
    list-style: none;
    font-weight: 600;
    font-size: 18px;
    background-color: $breadcrumb-bg;
    @include border-radius($breadcrumb-border-radius);
    @media (max-width: 536px) {
        font-size: 14px;
    }

    a {
        color: #000;
    }
}

.breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    display: inline;

    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding;

        &::before {
            font: var(--fa-font-solid);
            vertical-align: middle;
            font-size: 12px;
            padding-right: 10px;
            display: inline-block; // Suppress underlining of the separator in modern browsers
            color: $breadcrumb-divider-color;
            content: $breadcrumb-divider;
        }
    }

    // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
    // without `<ul>`s. The `::before` pseudo-element generates an element
    // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
    //
    // To trick IE into suppressing the underline, we give the pseudo-element an
    // underline and then immediately remove it.
    + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }

    // stylelint-disable-next-line no-duplicate-selectors
    + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

    &.active {
        color: #5c9f09;
    }
}
