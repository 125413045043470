// Default Variables

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $primary !default;
$slick-next-character: "\E684" !default;
$slick-prev-character: "\E686" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Slider */

.slick-list {
    .slick-loading & {
        background: $white slick-image-url("../../../images/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    cursor: pointer;
    color: $gray-600;
    top: 50%;
    transform: translate(0, -80%);
    padding: 0;
    border: none;
    outline: none;
    box-shadow: $box-shadow-default;
    transition: all .2s;
    font-family: 'Pe-icon-7-stroke';
    @include border-radius(50px);
    background: $white;
    z-index: 6;
    overflow: hidden;

    &::before {
        font-size: 35px;
        height: 40px;
        line-height: 40px;
        width: 40px;
        display: block;
    }

    &:hover, &:focus {
        outline: none;
        box-shadow: $box-shadow-lg;
        color: $primary;
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}

.slick-prev {
    left: 0;

    &:before {
        content: $slick-prev-character;

    }
}

.slick-next {
    right: 0;

    &:before {
        content: $slick-next-character;
    }
}

/* Dots */

.slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: ($layout-spacer-x * 0.5) 0 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
        transform: scale(.8);
        transition: all .2s;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 14px;
            width: 14px;
            background: $primary;
            cursor: pointer;
            position: relative;
            @include border-radius(20px);
            color: transparent;

            &::before {
                content: '';
                position: absolute;
                background: $white;
                left: 50%;
                top: 50%;
                margin: -4px 0 0 -4px;
                display: block;
                width: 8px;
                height: 8px;
                @include border-radius(20px);
            }

        }

        &.slick-active {
            transform: scale(1.2);
        }
    }
}

.slick-center {
    transform: scale(1.1);
}

.slick-slider {
    .slide-img-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: rgb(247, 251, 254);
        background-position: bottom center;
        opacity: 1;
        z-index: 10;
    }

    .slider-content {
        position: relative;
        z-index: 15;
        text-align: center;
        margin: 0 ($spacer-lg * 2);

        h3 {
            font-size: $h3-font-size;
            font-weight: bold;
            margin: 0 1rem;
            margin-bottom: 100%;
            color: rgb(37, 74, 151);
        }

        p {
            font-size: $h6-font-size;
            opacity: .7;
        }
    }
}

// slider-light

.slider-light {
    .slick-dots {
        position: absolute;
        bottom: 10px;

        li button {
            background: rgba(255, 255, 255, 0.25);
        }
    }

    .slick-prev,
    .slick-next {
        background: transparent;
        color: $white;
        box-shadow: 0 0 0 0 transparent;

        &:hover {
            background: rgba(255, 255, 255, .15);
        }
    }

    .slick-next {
        right: 15px;
    }

    .slick-prev {
        left: 15px;
    }

    .slider-content {
        color: $white;
    }
}
