// Rating

// Horizontal
.br-theme-bars-horizontal .br-widget {
  width: 120px;
  white-space: nowrap;
}
.br-theme-bars-horizontal .br-widget a {
  display: block;
  width: 120px;
  height: 5px;
  background-color: #fbedd9;
  margin: 1px;
}
.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
  background-color: #EDB867;
}
.br-theme-bars-horizontal .br-widget .br-current-rating {
  width: 120px;
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  color: #EDB867;
}
.br-theme-bars-horizontal .br-readonly a {
  cursor: default;
}
.br-theme-bars-horizontal .br-readonly a.br-active,
.br-theme-bars-horizontal .br-readonly a.br-selected {
  background-color: #f2cd95;
}
.br-theme-bars-horizontal .br-readonly .br-current-rating {
  color: #f2cd95;
}
@media print {
  .br-theme-bars-horizontal .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-horizontal .br-widget a.br-active,
  .br-theme-bars-horizontal .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
  }
  .br-theme-bars-horizontal .br-widget .br-current-rating {
    color: black;
  }
}

//Movie
.br-theme-bars-movie .br-widget {
  height: 10px;
  white-space: nowrap;
}
.br-theme-bars-movie .br-widget a {
  display: block;
  width: 60px;
  height: 8px;
  float: left;
  background-color: #bbcefb;
  margin: 1px;
}
.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected {
  background-color: #4278F5;
}
.br-theme-bars-movie .br-widget .br-current-rating {
  clear: both;
  width: 240px;
  text-align: center;
  font-weight: 600;
  display: block;
  padding: .5em 0;
  color: #4278F5;
  font-weight: 400;
}
.br-theme-bars-movie .br-readonly a {
  cursor: default;
}
.br-theme-bars-movie .br-readonly a.br-active,
.br-theme-bars-movie .br-readonly a.br-selected {
  background-color: #729bf8;
}
.br-theme-bars-movie .br-readonly .br-current-rating {
  color: #729bf8;
}
@media print {
  .br-theme-bars-movie .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-movie .br-widget a.br-active,
  .br-theme-bars-movie .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
  }
  .br-theme-bars-movie .br-widget .br-current-rating {
    color: black;
  }
}

// Pill
.br-theme-bars-pill .br-widget {
  white-space: nowrap;
}
.br-theme-bars-pill .br-widget a {
  padding: 7px 15px;
  background-color: #bef5e8;
  color: #50E3C2;
  text-decoration: none;
  font-size: 13px;
  line-height: 3;
  text-align: center;
  font-weight: 400;
}
.br-theme-bars-pill .br-widget a:first-child {
  -webkit-border-top-left-radius: 999px;
  -webkit-border-bottom-left-radius: 999px;
  -moz-border-radius-topleft: 999px;
  -moz-border-radius-bottomleft: 999px;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}
.br-theme-bars-pill .br-widget a:last-child {
  -webkit-border-top-right-radius: 999px;
  -webkit-border-bottom-right-radius: 999px;
  -moz-border-radius-topright: 999px;
  -moz-border-radius-bottomright: 999px;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}
.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
  background-color: #50E3C2;
  color: white;
}
.br-theme-bars-pill .br-readonly a {
  cursor: default;
}
.br-theme-bars-pill .br-readonly a.br-active,
.br-theme-bars-pill .br-readonly a.br-selected {
  background-color: #7cead1;
}
@media print {
  .br-theme-bars-pill .br-widget a {
    border: 1px solid #b3b3b3;
    border-left: none;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-pill .br-widget a.br-active,
  .br-theme-bars-pill .br-widget a.br-selected {
    border: 1px solid black;
    border-left: none;
    background: white;
    color: black;
  }
  .br-theme-bars-pill .br-widget a:first-child {
    border-left: 1px solid black;
  }
}

// Stars
.br-theme-css-stars .br-widget {
  height: 28px;
  white-space: nowrap;
}
.br-theme-css-stars .br-widget a {
  text-decoration: none;
  height: 18px;
  width: 18px;
  float: left;
  font-size: 23px;
  margin-right: 5px;
}
.br-theme-css-stars .br-widget a:after {
  content: "\2605";
  color: #d2d2d2;
}
.br-theme-css-stars .br-widget a.br-active:after {
  color: #EDB867;
}
.br-theme-css-stars .br-widget a.br-selected:after {
  color: #EDB867;
}
.br-theme-css-stars .br-widget .br-current-rating {
  display: none;
}
.br-theme-css-stars .br-readonly a {
  cursor: default;
}
@media print {
  .br-theme-css-stars .br-widget a:after {
    content: "\2606";
    color: black;
  }
  .br-theme-css-stars .br-widget a.br-active:after,
  .br-theme-css-stars .br-widget a.br-selected:after {
    content: "\2605";
    color: black;
  }
}

// 1 to 10

.br-theme-bars-1to10 .br-widget {
  height: 50px;
  white-space: nowrap;
}
.br-theme-bars-1to10 .br-widget a {
  display: block;
  width: 12px;
  padding: 5px 0;
  height: 28px;
  float: left;
  background-color: #fbedd9;
  margin: 1px;
  text-align: center;
}
.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-1to10 .br-widget a.br-selected {
  background-color: #EDB867;
}
.br-theme-bars-1to10 .br-widget .br-current-rating {
  font-size: 20px;
  line-height: 2;
  float: left;
  padding: 0 20px 0 20px;
  color: #EDB867;
  font-weight: 400;
}
.br-theme-bars-1to10 .br-readonly a {
  cursor: default;
}
.br-theme-bars-1to10 .br-readonly a.br-active,
.br-theme-bars-1to10 .br-readonly a.br-selected {
  background-color: #f2cd95;
}
.br-theme-bars-1to10 .br-readonly .br-current-rating {
  color: #f2cd95;
}
@media print {
  .br-theme-bars-1to10 .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    height: 38px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-1to10 .br-widget a.br-active,
  .br-theme-bars-1to10 .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
  }
  .br-theme-bars-1to10 .br-widget .br-current-rating {
    color: black;
  }
}
